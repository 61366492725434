<template>
  <div>
    <v-data-table :headers="headers" :items="items" dense>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="2">
              <v-select
                outlined
                flat
                dense
                background-color="white"
                v-model.number="year"
                :items="listYear"
                :rules="[v => !!v || 'Year is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2">
              <v-btn color="primary" @click="search" class="mr-2">
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn color="success" @click="excel">
                <v-icon>
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ items.findIndex(x => x === item) + 1 }}
      </template>
      <template v-slot:item.month="{ item }">
        {{ listMonth.find(x => x.value === item.month).text }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ formatPrice(item.amount) }}
      </template>
      <template v-slot:item.otherAmount="{ item }">
        {{ formatPrice(item.otherAmount) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import fileDownload from "js-file-download";

export default {
  name: "working-capital",
  data: () => ({
    year: new Date().getFullYear(),
    headers: [],
    items: [],
    listMonth: [
      {
        text: "Januari",
        value: 1,
      },
      {
        text: "Februari",
        value: 2,
      },
      {
        text: "Maret",
        value: 3,
      },
      {
        text: "April",
        value: 4,
      },
      {
        text: "Mei",
        value: 5,
      },
      {
        text: "Juni",
        value: 6,
      },
      {
        text: "Juli",
        value: 7,
      },
      {
        text: "Agustus",
        value: 8,
      },
      {
        text: "September",
        value: 9,
      },
      {
        text: "Oktober",
        value: 10,
      },
      {
        text: "November",
        value: 11,
      },
      {
        text: "Desember",
        value: 12,
      },
    ],
  }),
  computed: {
    ...mapState("incomeStatement", ["listType"]),
    listYear() {
      const year = new Date().getFullYear();
      let array = [];
      for (let i = 0; i <= 5; i++) {
        array.push(year + i);
      }
      for (let i = 1; i <= 5; i++) {
        array.unshift(year - i);
      }
      return array;
    },
  },
  created() {
    this.getInitialData();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setHeader() {
      this.headers = [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Month",
          value: "month",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: this.year,
          value: "amount",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: this.year - 1,
          value: "otherAmount",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ];
    },
    search() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("report/workingCapital", this.year)
        .then(response => {
          this.setHeader();
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    excel() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("report/workingCapitalExcel", this.year)
        .then(response => {
          fileDownload(response.data, `working-capital.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.$store.dispatch("incomeStatement/getType")])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
